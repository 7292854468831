
import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TableBody, TableCell, Table, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LeaderboardUserIcon from '../../../Images/LeaderboardUserIcon.png'
import icon1L from "../../../Images/1icon.png";
import icon2L from "../../../Images/2icon.png";
import icon3L from "../../../Images/3icon.png";
import iconwithstar from "../../../Images/iconwithstar.png";
import dropdowniconL from "../../../Images/dropdownicon.png";
import Certificate from '../Certificate/Certificate'
import ReactToPrint from 'react-to-print';
import moment from 'moment-timezone';
import '../../../Styling/certificate.css';
import logo from '../../../Images/LttsLogo.png';
import appstudiologo from '../../../Images/Appstudiopng.png';
import { useMsal } from "@azure/msal-react";
import '../../../Styling/leaderboard.css';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@mui/material/Tooltip";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 450,
    marginBottom: '5px'
  },
  tbody: {
    fontSize: '13px'
  },

  Mytooltip: {
    borderRadius: '60px',
    paddingTop: '9px',
    paddingBottom:'9px',
    textWrap: 'wrap',
    fontSize: '1rem',
    cursor:'pointer'
  },
});

export default function CustomizedDialogs({ details,onClick }) {
  const { instance } = useMsal();
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Download Certificate', 'Place Holder', 'Place Holder'];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDialog71_1, setOpenDialog71_1] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const classes = useStyles();
  let componentRef = useRef();
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCloseDialog71_1 = () => {
    setOpenDialog71_1(false);
  };
  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };

  const sortedArray = details.sort((a, b) => a.rank > b.rank);
  let userRank;
  const loggedUser = instance.getAllAccounts()[0]?.username;
  console.log("Logged in User is :",loggedUser)
  sortedArray.filter(item => item.email == loggedUser).map((user) => userRank = user.rank)
  console.log("Userrank is : ", sortedArray)

  //Custom Tooltip
  const CustomTooltip =({index})=>{
    return(
      <div>
          <p>User Email : { sortedArray[index].email}</p>  
         
          <p>Components Count : {sortedArray[index].publishedCount}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClick} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          Leaderboard
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick} sx={{ position: 'absolute', right: "8px", top: "8px", color: 'white', }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialogContent">
          <img src={LeaderboardUserIcon} alt="Leaderboard User Icon" className="user-icon"/>
          <p className="yourrank"> Your Rank
            <span className="rank-no1">{userRank}</span>
            <img className="dropdownIcon" src={dropdowniconL} onKeyDown={handleDropdownClick} />
          </p>
          {isDropdownOpen && (
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                position: "absolute",
                top: "101px", // adjust as needed
                left: "438px", // adjust as needed
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: '14px'
              }}>
              <li
                style={{
                  padding: "4px 2px",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  borderRadius: "4px",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")}
              >
                <Certificate list={sortedArray.filter(item => item.email == loggedUser)}/>
              </li>
              <li style={{padding: "4px 3px", cursor: "pointer", backgroundColor: "transparent", borderRadius: "4px"}}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")}
                onClick={() => console.log("More clicked")}
                onKeyDown={() => console.log("More clicked")}>
                More
              </li>
            </ul>
          )}
          <div className="rectangle-container">
          <Tooltip title={<CustomTooltip index={1} />} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle2">
              <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "28px" }} alt="iconwithstar" />
              <img src={icon2L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "14px", right: "40px" }} alt="LeaderboardIcon" />
              {sortedArray[1] && (
                sortedArray[1].senderName.length<=18 ? 
                <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                  {sortedArray[1].senderName}
                </p> :
                <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'12px',fontSize:'small'}}>
                {sortedArray[1].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={0}/>} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle1">
              <img src={iconwithstar} style={{ height: "70px", width: "70px", marginLeft: "31px", marginTop: "-30px" }} alt="iconwithstar" />
              <img src={icon1L} style={{ height: "65px", width: "65px", marginTop: "24px", marginLeft: "28px" }} alt="Leaderboar Icon" />
              {sortedArray[0] && (
                sortedArray[0].senderName.length<=18 ? 
                <p  style={{ position: "relative",  bottom:"72%", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                  {sortedArray[0].senderName}
                </p> :
                <p  style={{ position: "relative", bottom:"72%", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'24px'}}>
                {sortedArray[0].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={2}/>} classes={{ tooltip: classes.Mytooltip }} style={{cursor:"pointer"}}>
            <div className="blue-rectangle3">
              <img src={iconwithstar} style={{ height: "45px", width: "45px", position: "relative", top: "-18px", left: "28px" }} alt="iconwithstar" />
              <img src={icon3L} style={{ height: "25px", width: "25px", position: "absolute", bottom: "14px", right: "40px" }} alt="Leader Icon" />
              {sortedArray[2] && (
               sortedArray[2].senderName.length<=18 ? 
               <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' }}>
                 {sortedArray[2].senderName}
               </p> :
               <p  style={{ position: "relative", top: "-26px", color: "white", fontWeight: "bold", textAlign: 'center',fontSize:'small' ,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '80px',left:'12px'}}>
               {sortedArray[2].senderName}
             </p>
              )}
            </div>
            </Tooltip>
          </div>

          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader className={classes.table} aria-label="sticky table">
              <TableHead style={{ display: 'block', maxHeight: 185, overflow: 'auto'}}>
                <TableCell style={{width:"1%"}}>Rank</TableCell>
                <TableCell style={{width:"20%"}}>Name</TableCell>
                <TableCell style={{width:"20%"}}>Email</TableCell>
                <TableCell style={{width:"20%"}}>Components Count</TableCell>
              </TableHead>
              <TableBody className={classes.tbody} style={{ display: 'block', maxHeight: 185, overflow: 'auto'}}>
                {sortedArray.slice(3,).map((i) => (
                  <TableRow>
                    <TableCell style={{width:"7%"}}>{i.rank}</TableCell>
                    <TableCell style={{width:"20%",paddingLeft:"20px !important"}}>{i.senderName}</TableCell>
                    <TableCell style={{width:"20%",paddingLeft:"12px !important"}} className="text-break">{i.email}</TableCell>
                    <TableCell style={{width:"20%",paddingLeft:"18px !important"}}> {i.publishedCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className='okButton'>
          <Button sx={{position:"fixed"}} onClick={onClick} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}