import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardHeader } from '@mui/material';
import '../../Styling/leaderboard.css';
import Tabview from './Tab';
import userIcon from '../../Images/Consumers.png';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import { FaUsers } from "react-icons/fa6";
import DialogConusmer from './Consumer/DialogConsumer';
import ComponentList from './Table/UserTable';

const useStyles = makeStyles({
    boardcard: {
        height: '360px',
        position: 'relative',
        bottom: 6,
        margin: '7px 0px 0px 2px'
    },
    // container: {},
    cards: {
        position: 'relative',
        margin: '4px 7px 5px 7px',
        cursor:'pointer'
    },
    count:{
    fontSize: 'xx-large'
    }
});

const Leaderboard = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [componentsList, setComponentsList] = useState([]);
    const [consumersList, setConsumersList] = useState([]);
    const [userClick, setUserClick] = useState(false);
    const [compoClick, setCompoClick] = useState(false);

    const fetchData = () => {
        fetch("https://prod.appstudioltts.com/api/auth")
            .then((response) => response.json())
            .then((data) => {
                setConsumersList(
                    data.map((item) => ({
                        id: item.UserMailID,
                        name: item.UserName,
                        type: item.UserType,
                        BU: item.DeliveryGroup,
                        DU: item.DeliveryUnit,
                    }))
                );
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        fetch("https://prod.appstudioltts.com/api/linkstateupdate/published-components")
            .then((response) => response.json())
            .then((data) => {
                setComponentsList(data);
            })
            .catch((error) => {
                console.error("Error fetching component details:", error);
            });
    };

    function handleUser (){
        setUserClick(!userClick);
    }

    function handleCompo () {
        setCompoClick(!compoClick)
    }

    useEffect(() => {
        fetchData();
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
        <div className={classes.container} >
            <Card className={classes.boardcard}>
                <CardContent>
                    <Typography sx={{ fontSize: '14px', fontWeight:'bolder' }}>Leaderboard</Typography>
                    <Typography><Tabview /></Typography>
                </CardContent>
            </Card>
            <Card  onClick={handleUser} className="cardheight">
                <CardContent display="flex" alignItems="end">
                    <Box display="flex"  alignItems="center" justifyContent="center" textAlign="center" fontSize='18px' fontWeight='bolder' ml={1} marginRight='22px'>
                        <div className={classes.count}>{consumersList.length}</div>
                    </Box>
                    <div className="totalUsers">
                        <p style={{fontWeight:"bolder",color:'darkgray'}}>Total Users</p>
                        {/* <span style={{fontWeight:'bolder',color:'darkgray', position:'absolute', margin:"0 auto", display:"block"}}>Total Users</span> */}
                        <FaUsers style={{height:'2rem',width:'2rem'}} />
                    </div>
                </CardContent>
            </Card>
            <Card onClick={handleCompo} className="cardheight">
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" textAlign="center" fontSize='18px' fontWeight='bolder' ml={1} marginRight='22px'>
                        <div className={classes.count}>{componentsList.length}</div>
                    </Box>
                    <div className="totalUsers">
                    <p style={{fontWeight:"bolder",color:'darkgray'}}>Total Components</p>
                        {/* <span style={{fontWeight:'bolder',color:'darkgray'}}>Total Components</span> */}
                        <FaUsers style={{height:'2rem',width:'2rem'}} />
                    </div>
                </CardContent>
            </Card>
        </div>
        {userClick?<><DialogConusmer onClick={handleUser} details={consumersList} title="Total Users" style={{margin:"0 auto"}}/></>:""}
        {compoClick?<><ComponentList onClick={handleCompo} details={componentsList} style={{margin:"0 auto"}} /></>:""}
        </>
    )
}
export default Leaderboard;